@import "variables.scss";

@import "core/palette.scss";
@import "app-variables.scss";

@import "bootstrap/reboot";
@import "bootstrap/badge";
@import "bootstrap/buttons";
@import "bootstrap/card";
@import "bootstrap/nav";
@import "bootstrap/forms";
@import "bootstrap/carousel";
@import "bootstrap/modal";
@import "bootstrap/navbar";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/type";

.navbar-search-term {
	width: 22rem;
}

.ayo-brand {
	background-image: linear-gradient(30deg, #0a2969, #272940);
    background-repeat: repeat-y;
    opacity: 0.9;
}